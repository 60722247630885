.form-parent {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 400px;
  text-align: center;
  padding: 30px;
  border-radius: 12px;
}

.title-style {
  padding: 20px;
  border-radius: 15px;
  background-color: #fdbb41;
}
.submitBtn {
  background-color: #fdbb41;
  padding: 12px 22px;
  border: none;
  border-radius: 10px;
}
.submitBtn:hover{
  background-color: '#fca13f';
  padding: 12px 22px;
  border: none;
  border-radius: 10px;
}
.logo {
  max-width: 100%;
  width: 151px;
  padding: 13px 19px;
  margin-bottom: 11px;
}

.invalid-page-container {
  /* height: 60vh; */
  /* width: 100%; */
  padding: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.invalid-container-main {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invalid-container-card {
  background-color: #fff;
  width: min(800px, 80vw);
  padding: 40px;
  border-radius: 16px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.text-container > p {
  font-size: 32px;
  font-weight: 900;
  margin: 0;
  padding: 0;
  text-align: center;
}
